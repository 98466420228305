<template>

    <div ref="editor" class="editor"></div>

</template>
<script>
const ace = require('brace');
require('brace/mode/json');
require('brace/theme/eclipse');

export default {
    components: {},
    props: {
        value: {
            type:String,
            default:'',
        },
        theme: {
            tyle: String,
            default: 'tomorrow_night'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        language: {
            type: String,
            default: 'json'
        },
    },
    data() {
        return {}
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value:{
            handler: function (val) {
                if(this.editor&&val!=this.editor.getValue()){
                    this.editor.setValue(val);
                }
            }
        },
        disabled:{
            handler: function (val) {
                this.editor.setReadOnly(val);
            }
        },
    },
    mounted() {
        this.editor = ace.edit(this.$refs.editor);
        this.editor.renderer.setShowPrintMargin(false);
        this.editor.setReadOnly(this.disabled);
        this.editor.setTheme('ace/theme/eclipse');
        this.editor.getSession().setMode(`ace/mode/${this.language}`);
        this.editor.setValue(this.value||'');
        this.editor.on('change',(e,b,c)=>{
            this.$emit('change', this.editor.getValue());
        })
    },
    methods: {}
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.editor {
    height:160px;
    border:#ddd solid 1px;
}
</style>
